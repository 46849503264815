import axios from 'axios';

axios.defaults.baseURL = 'https://chameleon-fancentral-production-ase-function-app.azurewebsites.net/api';
axios.defaults.headers = {
    'Content-Type': 'application/json',
};

let responseInterceptor: any;

export const setUpAxiosClientIdHeader = (clientId: string) => {
    axios.defaults.headers.client_id = clientId;
};

export const setUpAxiosAuthHeader = (accessToken: string) => {
    axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
};


export const tearDownAxiosConfig = () => {
    delete axios.defaults.headers.client_id;
    delete axios.defaults.headers.Authorization;
    axios.interceptors.response.eject(responseInterceptor);
};

export default axios;
